:root {
  /* test status */
  --test-status-failed: #df2a02;
  --test-status-passed: #4bb102;
  --test-status-warning: #ecb201dc;
  --test-status-no-limit: #222e3e;
  --test-status-untested: #6c757c;

  /* landing */
  --landing-in-process: #017bff;
  --landing-archived: #4bb102;
  --landing-not-start: #6c757c;
}
