.chatContainer {
  display: flex;
  flex-direction: column;
  height: 90vh;
}

.messageContainer {
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 20px;
  padding-right: 16px;
}

.iconContainer {
  padding: 8px 0;
  text-align: center;
}

.userIcon {
  color: slategray;
}

.assistantIcon {
  color: royalblue;
}

.messageContent {
  padding: 8px;
}

.assistantMessageHeader {
  text-align: right;
}

.assistantMessage {
  background-color: white;
  border-radius: 4px;
  border: 1px solid lightgray;
  padding: 8px;
  color: royalblue;
}

.userMessage {
  color: slategray;
}

.displayText {
  white-space: pre-wrap;
}

.sourcesSection {
  margin-top: 10px;
}

.sourcesContent {
  background-color: #f2f3f3;
  padding: 10px;
  margin-top: 20px;
}

.contentTextFull {
  margin-top: 10px;
  white-space: pre-wrap;
  word-break: break-word;
}
